/* LandingPage.css */

body {
  margin: 0;
  padding: 0;
}

.background-video-container {
  position: relative;
  width: 100%;
  height: 100vh;body {
    margin: 0;
    padding: 0;
  }
  
  .content {
    background-color: #FEFEFE; /* Change the background color */
    padding: 20px;
    color: #000; /* Ensure text is visible over the background */
  }
  
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  a.text-muted {
    font-size: 14px;
    cursor: pointer;
  }
  
  img.img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .modal-body p {
    margin-bottom: 10px;
  }
  
  .animate-bounce {
    animation: bounce 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 767px) {
    .content {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  
    .container {
      margin-top: 0;
      margin-bottom: 0;
    }
  
    .row {
      flex-direction: column-reverse; /* Stack the image and text on top of each other */
    }
  
    .col-md-6 {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
    }
  
    img.img-fluid {
      width: 80%;
    }
  
    .fixed-bottom {
      position: static; /* Make the button static instead of fixed */
      margin-bottom: 20px;
    }
  
    .btn {
      width: 100%;
      font-size: 1.2rem;
      padding: 15px;
    }
  }
  
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: #fff; /* Ensure text is visible over the video */
}

h1 {
  font-size: 2rem;
}

h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

a.text-muted {
  font-size: 14px;
  cursor: pointer;
}

img.img-fluid {
  max-width: 100%;
  height: auto;
}

.modal-body p {
  margin-bottom: 10px;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Mobile Styles */
@media (max-width: 767px) {
  .background-video-container {
    height: auto;
  }

  .background-video {
    height: 300px; /* Limit video height on mobile */
  }

  .content {
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row {
    flex-direction: column;
    /*flex-direction: column-reverse;  Stack the image and text on top of each other */
  }

  .col-md-6 {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  img.img-fluid {
    width: 80%;
  }

  .fixed-bottom {
    position: static; /* Make the button static instead of fixed */
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    font-size: 1.2rem;
    padding: 15px;
  }
}
