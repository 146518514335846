/* Main container for city hotels */
.city-hotels-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

/* Sidebar styling for the city dropdown */
.city-dropdown-sidebar {
  flex: 1;
  margin-right: 20px;
  max-width: 250px;
  padding: 10px;
}

/* Hotel list section styling */
.hotel-list-section {
  flex: 4;
  display: flex;
  flex-direction: column;
}

/* Heading for the hotel list */
.hotel-list-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Container for hotel cards */
.hotel-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Horizontal hotel card layout */
.hotel-card-horizontal {
  display: flex;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Image gallery within the hotel card */
.image-gallery {
  display: flex;
  gap: 10px;
  margin-right: 20px;
}

/* Main image styling */
.main-image-horizontal {
  width: 232px;
  height: 213px;
  overflow: hidden;
  border-radius: 6px;
}

.large-image-horizontal {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Thumbnail styling */
.thumbnails-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.thumbnail-horizontal {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.2s ease;
}

.thumbnail-horizontal:hover {
  transform: scale(1.1);
}

/* Hotel details section */
.hotel-details-horizontal {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hotel-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.hotel-location {
  font-size: 16px;
  color: #888;
  margin-bottom: 10px;
}

/* Features and amenities */
.hotel-features {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
}

.feature {
  background-color: #f8f9fa;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.more-features {
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

/* Price section */
.price-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.current-price {
  font-size: 18px;
  font-weight: bold;
  color: #28a745;
  margin-right: 10px;
}

.original-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 10px;
}

.discount {
  color: #dc3545;
}

/* Action buttons */
.card-actions {
  display: flex;
  gap: 10px;
}

.btn-book-now {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.btn-book-now:hover {
  background-color: #218838;
}

/* Modal custom styles */
.custom-modal-width {
  max-width: 100%;

}
.modal-dialog {
  max-width: 900px;
  margin: 1.75rem auto;
}

.modal-body {
  padding: 20px 30px; /* Adjust padding for a spacious layout */
}

.medium-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.description-section {
  margin-top: 20px;
}

.amenities-section {
  margin-top: 20px;
}

.amenities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.amenity-item {
  flex-basis: 45%;
}

.amenity-subitems {
  list-style-type: none;
  padding-left: 0;
}

.amenity-text {
  margin-bottom: 5px;
}
