/* Container styling - display cities in a row with proper spacing */
.city-dropdown-row {
    display: flex;
    flex-wrap: wrap;
    background-color: #f8f9fa; /* Page background color */
    padding: 10px 20px; /* Add padding inside the container */
    justify-content: space-between; /* Add space between city buttons */
    gap: 15px; /* Add gap between the city buttons */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* City button styling */
  .city-button {
    background-color: #f8f9fa; /* Match button background with page background */
    border: none;
    padding: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    border-right: 1px solid #ddd;
    color: #333;
    min-width: 150px; /* Set a minimum width for uniform buttons */
    text-align: center; /* Center text */
    position: relative;
    z-index: 1;
  }
  
  /* Add hover effect to buttons */
  .city-button:hover {
    background-color: #e9ecef; /* Slightly darker on hover */
  }
  
  /* All cities button */
  .all-cities-button {
    padding: 15px;
    background-color: #f8f9fa; /* Match button background with page background */
    color: #007bff;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    display: block;
  }
  
  .all-cities-button:hover {
    color: #0056b3;
    background-color: #e9ecef;
  }
  
  /* City container */
  .city {
    margin: 0;
    position: relative;
    flex: 1 1 auto; /* Allow items to grow and shrink with screen size */
  }
  
  /* Add dropdown styling */
  .locations-dropdown {
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 2;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: none; /* Hide by default */
  }
  
  /* Show dropdown on city hover */
  .city:hover .locations-dropdown {
    display: block;
  }
  
  /* Location link styling */
  .location {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  
  /* Hover effect for location links */
  .location:hover {
    background-color: #f1f1f1;
    color: #007bff;
  }
  