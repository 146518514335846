/********** Template CSS **********/
:root {
    --primary: #FEA116;
    --light: #F1F8FF;
    --dark: #126EAC ;
}

.fw-medium {
    font-weight: 500 !important;
}

.fw-semi-bold {
    font-weight: 600 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/

.btn-container {
    display: flex;
    gap: 10px; 
}

.btn {
    font-weight: 500;
    text-transform: uppercase;
    transition: .5s;
    width: 100%;
}

/* Primary and secondary button colors */
.btn.btn-primary,
.btn.btn-secondary {
    color: #FFFFFF;
}

/* Specific button sizes */
.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

/* Square button alignment */
.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 2px;
}

/* Maintain consistent button size across all devices */
.btn.btn-primary, .btn.btn-dark {
    width: 100%;
    max-width: 200px; /* Adjust this value as per your design */
    padding: 10px 20px;
    font-size: 14px;
}

/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
    .btn.btn-primary, .btn.btn-dark {
        width: 100%;
        max-width: 200px; /* Ensures consistency with desktop view */
        padding: 10px 20px;
        font-size: 14px;
    }
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary);
}
.logo-bg {
    background-color: white; /* Set white background */
    padding: 0px; /* Add some padding to give space around the logo */
    border-radius: 5px; /* Optional: Add rounded corners */
  }
  

@media (max-width: 991.98px) {
    .navbar-dark .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }
}


/*** Header ***/

.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(15, 23, 43, .7);
    z-index: 1;
}


.carousel-control-prev,
.carousel-control-next {
    width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
}

@media (max-width: 991.98px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-header-inner {
    background: rgba(15, 23, 43, .7);
}

.breadcrumb-item + .breadcrumb-item::before {
    color: var(--light);
}
/* Ensure dropdown menu is visible and styled correctly */
.nav-item.dropdown .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #FEFEFE;
    background-color: #052a43;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  @media (min-width: 992px) {
    .nav-item.dropdown:hover .dropdown-menu {
      display: block;
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .nav-item.dropdown .dropdown-menu.show {
    display: block;
  opacity: 1;
  transform: translateY(0);
  }
  .navbar-collapse {
    text-align: center;
  }
  
  .nav-item.dropdown .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #FEFEFE;
    text-align: inherit;
    background-color: transparent;
    border: 0;
    border-radius: 0.25rem;
  }
  
  .nav-item.dropdown .dropdown-item:hover {
    color: #fff;
    background-color: #007bff;
  }
  
  /* Optional: Add a transition effect */
  .nav-item.dropdown .dropdown-menu {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .nav-item.dropdown .dropdown-menu.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .nav-item.dropdown .dropdown-menu {
    opacity: 0;
    transform: translateY(-10px);
  }
  

/* .booking {
    position: relative;
    margin-top: -100px !important;
    z-index: 1;
} */


/*** Section Title ***/
.section-title {
    position: relative;
    display: inline-block;
}

.section-title::before {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title::after {
    position: absolute;
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
    display: none;
}


/*** Testimonial ***/
.testimonial {
    background: linear-gradient(rgba(15, 23, 43, .7), rgba(15, 23, 43, .7)), url(../../public/assets/img/carousel-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.testimonial-carousel {
    padding-left: 65px;
    padding-right: 65px;
}

.testimonial-carousel .testimonial-item {
    padding: 30px;
    margin: 15px; /* Add margin between testimonials */
}

.testimonial-carousel .owl-nav {
    position: absolute;
    width: 100%;
    height: 40px;
    top: calc(50% - 20px);
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.testimonial-carousel .owl-nav .owl-prev,
.testimonial-carousel .owl-nav .owl-next {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: var(--primary);
    border-radius: 2px;
    font-size: 18px;
    transition: .5s;
}

.testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial-carousel .owl-nav .owl-next:hover {
    color: var(--primary);
    background: #FFFFFF;
}

/* Media Query for small screens */
@media (max-width: 768px) {
    .testimonial-carousel {
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .testimonial-carousel .testimonial-item {
        padding: 20px;
        margin: 10px; /* Adjust margin for smaller screens */
    }

    .testimonial-carousel .owl-nav {
        height: 30px;
        top: calc(50% - 15px);
    }

    .testimonial-carousel .owl-nav .owl-prev,
    .testimonial-carousel .owl-nav .owl-next {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
}
/*** About ***/
.image-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px; /* Space between images */
  }
  
  .image-wrapper {
    flex: 1 1 calc(50% - 15px); /* Take 50% of the container's width, minus the gap */
    height: 250px; /* Set a fixed height for consistency */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the images fill their container while maintaining aspect ratio */
  }
  
  @media (max-width: 768px) {
    .image-wrapper {
      flex: 1 1 100%; /* On mobile, take up 100% of the container width */
    }
  }
  

/*** Team ***/
.team-item,
.team-item .bg-primary,
.team-item .bg-primary i {
    transition: .5s;
}

.team-item:hover {
    border-color: var(--secondary) !important;
}

.team-item:hover .bg-primary {
    background: var(--secondary) !important;
}

.team-item:hover .bg-primary i {
    color: var(--secondary) !important;
}
/*** Holiday***/

.holiday-image {
    width: 100%;  /* Full width of the parent container */
    height: 250px;  /* Set a fixed height */
    object-fit: cover;  /* Ensures the image covers the entire area without distortion */
    border-radius: 10%; /* Rounded corners */
    margin: 0 10px;  /* Adds spacing between images */
  }
  
  .container {
    padding: 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .col-md-4 {
    padding: 0 15px; /* Adds padding between columns */
  }
  
/*** Newsletter ***/
/* Default (desktop/tablet view) */
.newsletter-form {
    position: relative;
  }
  
  .newsletter-form input {
    width: 100%;
    padding-right: 70px;
  }
  
  .newsletter-form button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
  }
  
  /* Mobile view (screens less than 768px) */
  @media (max-width: 768px) {
    .newsletter-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .newsletter-form input {
      width: 100%;
      margin-bottom: 10px;
      padding-right: 20px;
    }
  
    .newsletter-form button {
      position: static;
      width: 100%;
    }
  }
  


/*** Footer ***/
.newsletter {
    position: relative;
    z-index: 1;
}

.footer {
    position: relative;
    padding-top: 50px;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .3);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}

  