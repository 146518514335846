/* Sticky Search Container */
.sticky-search {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* General Styles for Inputs */
.form-control {
  border-radius: 0;
}

/* Dropdown menu styling */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  min-width: 100%;
  z-index: 1000;
}

/* Success button styles */
.btn-success {
  background-color: #126EAC;
  border-color: #126EAC;
}

.btn-success:hover {
  background-color: #FEA116; ;
  border-color: #FEA116; ;
}

/* Flex and Mobile-specific adjustments */
@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .col-md-12, .col-12 {
    width: 100%;
  }

  .mb-2 {
    margin-bottom: 15px;
  }

  .dropdown-menu {
    width: 100%;
  }

  .btn-success {
    width: 100%;
  }
}
